<template>
  <div class="relative">
    <q-chip
        square
        :icon="icon"
        :class="`q-legend ${textClass ? textClass : 'text-subtitle1'} q-pl-lg bg-gray-3`"
    >
      <img
          v-if="image"
          :src="image"
          style="max-height: 22px; width: 100%; object-fit: contains;"
      >

      <span :class="image ? 'q-ml-sm' : ''">
          {{ label }}
        </span>
    </q-chip>
  </div>
</template>

<script>
export default {
  name: 'QLegend',
  props: {
    image: {
      type: String,
      default () {
        return ''
      }
    },
    textClass: {
      type: String,
      default () {
        return ''
      }
    },
    icon: {
      type: String
    },
    label: {
      type: [String, Number],
      required: true,
      default () {
        return ''
      }
    }
  }
}
</script>

<style scoped>
.q-legend {
  position: relative;
}

.q-legend:before {
  border: 0 solid #0000;
  border-top-color: #bebebe;
  border-width: 9px 0 0 11px;
}
</style>
